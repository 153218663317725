













import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters } from 'vuex';
import User from 'common-modules/src/store/User';
import { Prop } from 'vue-property-decorator';
import JwlButton from '@/components/JwlButton.vue';

@Component({
  components: {
    JwlButton,
  },
  computed: mapGetters({
    currentUser: 'currentUser',
  }),
})
export default class JwlGradebookExport extends Vue {
  @Prop(String)
  courseCode!: string;

  currentUser!: User;

  url: string | null = null;
  status = 0;

  downloadGradebook (): void {
    if (this.status <= 0 && window.confirm(this.$t('gradebook.export-confirm').toString())) {
      this.status = 1;
      this.$store.dispatch('getData', `gradebook-export/${this.courseCode}`)
        .then((data) => {
          this.url = data;
          this.status = 2;
          window.location.assign(data);
        })
        .catch(() => {
          this.status = -1;
        });
    }
  }

  get showGradebookExportButton (): boolean {
    const allowedRoles = ['admin', 'facilitator'];
    return this.currentUser && allowedRoles.includes(this.currentUser.role);
  }
}
